:root {
  --rose: #fb6376;
  --eggshell: #f1e9da;
  --azalea: #f374ae;
  --litchi: #ff9770;
  --snow: #f4f4f4;
  --ash: #a8a8a8;
  --wine: #75485e;
  --black: #000000;
  --violet: #8e7dbe;
  --vespa: #fed766;
  --mantis: #78c569;
  --concrete: #e1e1e1;
  --ocean: #ff7765 !important;
  --ocean-4: #d69087 !important;
  --ocean-13: #e0f2f3;
  --ocean-34: #aedde0;
  --accent-color: var(--ocean);
  --black-20: rgba(0, 0, 0, 0.2);
  --white: #ffffff;
  --black-40: rgba(0, 0, 0, 0.4);
  --white-60: rgba(255, 255, 255, 0.6);
  --black-8: rgba(0, 0, 0, 0.08);
  --black-13: rgba(0, 0, 0, 0.13);
  --black-4: rgba(0, 0, 0, 0.04);
  --rose-4: rgba(251, 99, 118, 0.4);
  --black-60: rgba(0, 0, 0, 0.6);
  ---color-fill-black-44: rgba(0, 0, 0, 0.44);
  ---color-fill-black-21: rgba(0, 0, 0, 0.21);
  ---color-fill-concrete-34: #f5f5f5;
  ---color-fill-snow-34: #fbfbfb;
  ---color-fill-eggshell-34: #faf7f2;
  ---color-fill-accent-34: rgba(var(--accent-color), 0.34);
  ---color-fill-rose-34: #fdcad0;
  ---color-fill-rose-21: #fedee2;
  ---color-fill-rose-13: #ffebed;
  ---color-fill-rose-8: #fff3f4;
  ---color-fill-azalea-34: #fbcfe3;
  ---color-fill-azalea-21: #fce1ee;
  ---color-fill-azalea-13: #feedf5;
  ---color-fill-azalea-8: #fef4f9;
  ---color-fill-violet-34: #d8d3e9;
  ---color-fill-violet-21: #e7e3f1;
  ---color-fill-violet-13: #f0eef7;
  ---color-fill-violet-8: #f6f5fa;
  ---color-fill-mantis-34: #d1ebcc;
  ---color-fill-mantis-21: #e2f2df;
  ---color-fill-mantis-13: #eef8ec;
  ---color-fill-mantis-8: #f5fbf3;
  ---color-fill-vespa-34: #fef1cb;
  ---color-fill-vespa-21: #fef6de;
  ---color-fill-vespa-13: #fffaeb;
  ---color-fill-vespa-8: #fffcf3;
  ---color-fill-litchi-34: #ffdbce;
  ---color-fill-litchi-21: #ffe9e1;
  ---color-fill-litchi-13: #fff2ed;
  ---color-fill-litchi-8: #fff7f4;
  ---color-fill-wine-34: #d0c0c8;
  ---color-fill-wine-21: #e2d8dd;
  ---color-fill-wine-13: #ede7ea;
  ---color-fill-wine-8: #f4f1f3;
  --misc-tc: #2a80b9;
  ---color-fill-misc-whats-app: #1fbea5;
  ---color-fill-misc-pay-pal: #009cde;
  ---color-fill-misc-tc-34: #b6d4e7;
  --default-box-shadow: -2px 0 4px 0 var(--black-8);
}

:root {
  --system-font: SFProText, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font: var(--system-font);
}

html,
body {
  margin: 0;
  font-family: var(--system-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  height: 100%;
  background-color: var(--snow);
}

#root {
  height: 100%;
  min-width: 100vw;
  margin: 0;
}

.label-tiny {
  line-height: 20px;
  font-size: 10px;
}

.label-small {
  line-height: 22px;
  font-size: 12px;
}

.label-medium {
  line-height: 24px;
  font-size: 14px;
}

.lable-large {
  line-height: 26px;
  font-size: 16px;
}

.label-bold {
  font-weight: bold;
}

.rose-color {
  color: var(--rose);
}

.ash-color {
  color: var(--ash);
}
